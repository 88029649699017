import React from 'react';
import loadable from '@loadable/component';
import { logError } from 'logging';

import getAppType from 'helpers/getAppType';
import { BENTO_ORDERING_APP, CATERING_APP } from 'constants/apps';

const appComponent = {
  [BENTO_ORDERING_APP]: loadable(() =>
    import(/* webpackChunkName: 'bento_ordering' */ 'bento-ordering')
  ),
  [CATERING_APP]: loadable(() =>
    import(/* webpackChunkName: 'catering' */ 'catering')
  )
};

const UnsupportedRoute = () => <div>That route is not supported</div>;

const App = () => {
  let appType = null;
  try {
    appType = getAppType();
  } catch (e) {
    logError('Route is not supported', {}, e);
    return <UnsupportedRoute />;
  }

  const AppComponent = appComponent[appType];
  return <AppComponent />;
};

export default App;
