/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { DATADOG_SITE, DATADOG_SERVICE } from 'logging/constants';
import { getEnvironment } from 'config/helpers';
import { PRODUCTION } from 'config/constants';

export const initializeDatadog = () => {
  const { DATADOG_CLIENT_TOKEN, NODE_ENV, SHORT_COMMIT_HASH } = process.env;

  const env = getEnvironment();

  if (env !== PRODUCTION) {
    return;
  }

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: NODE_ENV,
    version: SHORT_COMMIT_HASH,
    sessionSampleRate: 100,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: []
  });
};
