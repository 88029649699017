/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';

const getArgsForConsole = (message, data = undefined, error = undefined) =>
  [message, data, error].filter(arg => arg !== undefined);

/**
 * Logs an INFO level message.
 *
 * @param {string} message
 * @param {Object.<string, any>} data
 * @param {Error} error
 *
 * @return {void}
 */
export const logInfo = (message, data = undefined, error = undefined) => {
  datadogLogs?.logger?.info(message, data, error);
  console.info(...getArgsForConsole(message, data, error));
};

/**
 * Logs a WARN level message.
 *
 * @param {string} message
 * @param {Object.<string, any>} data
 * @param {Error} error
 *
 * @return {void}
 */
export const logWarn = (message, data = undefined, error = undefined) => {
  datadogLogs?.logger?.warn(message, data, error);
  console.warn(...getArgsForConsole(message, data, error));
};

/**
 * Logs an ERROR level message.
 * This will create a Sentry log as well.
 *
 * @param {string} message
 * @param {Object.<string, any>} data
 * @param {Error} error
 *
 * @return {void}
 */
export const logError = (message, data = undefined, error = undefined) => {
  datadogLogs?.logger?.error(message, data, error);
  console.error(...getArgsForConsole(message, data, error));
};
