import { logWarn, logError } from 'logging';
import shouldRaiseError from 'logging/shouldRaiseError';

export default (error, info = null) => {
  const { message, stack } = error;

  if (!shouldRaiseError(error)) {
    logWarn(message, { info, stack });
  } else {
    if (message) {
      logError(message, { info, stack }, error);
    } else {
      logError(error.toString(), { info, stack }, error);
    }
  }
};
